import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/WhatWeOffer.css';
import { Navigation, Pagination, Grid } from 'swiper/modules';

const WhatWeOffer = () => {
    const items = [
        {
            icon: '/images/iconos/talent.png',
            title: 'Talent Acquisition Partnership',
            description: 'Collaborative support with your internal HR teams',
        },
        {
            icon: '/images/iconos/market.png',
            title: 'Market Insights & Competitor Analysis',
            description: 'Leverage real-time data to stay ahead in a competitive market',
        },
        {
            icon: '/images/iconos/salary.png',
            title: 'Salary Surveys & Benchmarking',
            description: 'Make informed decisions with our up-to-date industry compensation data',
        },
        {
            icon: '/images/iconos/hr.png',
            title: 'HR Consulting',
            description: 'Optimize your hiring strategy with expert advice tailored to your goals',
        },
        {
            icon: '/images/iconos/candidate.png',
            title: 'Candidate Database Access',
            description: 'Gain exclusive access to a curated network of renewable energy professionals',
        },
        {
            icon: '/images/iconos/customizd.png',
            title: 'Customized Talent Acquisition',
            description: 'Collaborative solutions designed to align with your internal HR processes.',
        },
        {
            icon: '/images/iconos/detailed.png',
            title: 'Detailed Reporting',
            description: 'Get clear insights with reports on recruitment metrics, candidate engagement, and hiring outcomes.',
        },
        {
            icon: '/images/iconos/improced.png',
            title: 'Improved Candidate Experience',
            description: 'Streamlining the hiring journey to attract and retain top-tier talent.',
        },
    ];

    // Agrupar los ítems en pares
    const groupedItems = [];
    for (let i = 0; i < items.length; i += 2) {
        groupedItems.push(items.slice(i, i + 2));
    }

    return (
            <div className="slider-container">
                <h2 className="slider-title">What We Offer</h2>
                <p className="slider-subtitle">
                    External agency speed and efficiency meets internal control and quality
                </p>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={2} // Mostrar 2 columnas por slider
                    pagination={{ clickable: true }}
                    breakpoints={{
                        640: { slidesPerView: 1 }, // 1 slide en pantallas pequeñas
                        768: { slidesPerView: 2 }, // 2 slides en pantallas medianas
                    }}
                >
                    {groupedItems.map((group, index) => (
                        <SwiperSlide key={index}>
                            <div className="group">
                                {group.map((item, idx) => (
                                    <div className="slide" key={idx}>
                                        <img src={item.icon} alt={item.title} className="slide-icon" />
                                        <div>
                                            <h3 className="slide-title">{item.title}</h3>
                                            <p className="slide-description">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
    );
};

export default WhatWeOffer;
